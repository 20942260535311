.footer {
    background-color: white;
    border-radius: 20px 20px 0 0;
    margin: 0 20px;
    font-family: "Bricolage Grotesque", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.footer-container-fluid p, .footer-container-fluid a {
    color: #6C5CE7;
    text-decoration: none;
    margin: 0;
    padding: 10px 0;
    font-size: 16px;
    line-height: 1.5;
}

.social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Consistent spacing between icons */
    margin-top: 10px;
}

.social-media a img {
    width: 30px;
    height: 30px;
    transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth hover effect */
}

.social-media a:hover img {
    transform: scale(1.1); /* Slightly enlarges the icon on hover */
    opacity: 0.8; /* Slightly fades the icon on hover */
}

/* Responsive styles */
@media (max-width: 768px) {
    .footer {
        padding: 15px;
        margin: 0 10px;
    }

    .social-media {
        gap: 15px;
    }

    .footer-container-fluid p, .footer-container-fluid a {
        margin: 5px 0;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 15px 10px;
        margin: 0;
    }

    .social-media {
        gap: 10px;
    }

    .social-media a img {
        width: 25px;
        height: 25px;
    }

    .footer-container-fluid p, .footer-container-fluid a {
        font-size: 13px;
    }
}
