.demo-card{
    border-radius: 10px;
    padding: 20px;
    background: linear-gradient(to top , white, transparent);
    transition: transform 0.2s;
    height: 20vh;
    width: 40vh;
    margin-bottom: 20px;
    z-index: 1;
}

@media (max-width: 768px) {
    .demo-card {
        display: none;
    }
}
