/* Basic styling for each card */
.card {
    border: 3px solid;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.2s, background-color 0.2s; /* Added transition for background color */
    height: 45vh;
    width: 40vh;
    font-family: "Bricolage Grotesque", sans-serif;
}

/* Scale up on hover and change background color */
.card:hover {
    transform: scale(1.05);
    background-color: var(--card-border-color);
    color: white;
}

/* Style for icon inside the card */
.icon {
    height: 50px;
    margin-bottom: 10px;
}

.icon img {
    margin-top: 10vh;
    height: 15vh;
    width: auto;
}

/* Text inside the card */
.card h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    text-align: left;
}

.card p {
    font-size: 1em;
    color: #333;
}

/* Responsive adjustments for mobile devices */
@media (max-width: 768px) {
    .card {
        height: 60vh; /* Adjust height for mobile */
        width: 100%; /* Adjust width to fit within mobile view */
        font-size: 0.8em; /* Adjust font size for better readability */
    }

    .icon {
        height: 40px; /* Adjust icon size for mobile */
    }

    .icon img {
        height: 20vh; /* Adjust image size for mobile */
    }

    .card h3 {
        font-size: 1.8em; /* Adjust header font size for mobile */
        padding: 20px 20px 0px 20px;
    }
}
