.projects-main{
    background-color: transparent;
}

.projects-main h1{
    margin: 0;
    font-size: 10vh; /* Adjust size for responsiveness */
    color: black;
    font-family: "Bricolage Grotesque", sans-serif;
    text-align: center;
}

.projects-main p{
    text-align: center;
    padding-top: 3vh;
}