.App-header {
    display: flex;
    flex-direction: row; /* Horizontal layout */
    justify-content: center; /* Center content horizontally */
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    margin: 20px;
    height: 85vh; /* Default height for larger screens */
    background-color: white;
    overflow: hidden;
    position: relative;
}

.hero-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: relative;
}

.hero-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 45%; /* Text covers 40% of the container width */
    height: 100%;
    padding: 20px;
    z-index: 1;
    background: transparent;
    overflow: hidden; /* Hide overflow */
    background-image: linear-gradient(to right, white 60%, transparent);
    box-sizing: border-box; /* Include padding in width */
}

.hero-upper-text {
    padding-bottom: 20px;
    background: radial-gradient(circle, white 50%, transparent);
}

.hero-text h1 {
    margin: 0;
    font-size: 7.5vh; /* Large size for responsiveness */
    color: black;
    font-family: "Bricolage Grotesque", sans-serif;
}

.hero-text h2 {
    font-size: 5vh; /* Smaller than h1 but still prominent */
    margin: 0;
    color: black;
    font-family: "Bricolage Grotesque", sans-serif;
    margin-top: 25vh;
}

.hero-text p {
    width: 85%; /* Ensure paragraph spans the width of the text container */
    font-size: 2vh; /* Smaller text size */
}

.hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgb(190, 190, 190); */
    overflow: hidden;
}

.mobile-view{
    display: none;
}

@media (max-width: 768px) {
    .App-header {
        flex-direction: column;
        align-items: center;
        height: 80%; /* Full height for mobile screens */
        margin: 20px; /* Remove margin for mobile screens */
        padding: 0; /* Remove padding for mobile screens */
    }

    .mobile-view {
        display: block; /* Show on mobile */
    }

    .laptop-view {
        display: none; /* Hide on mobile */
    }

    .hero-section {
        flex-direction: column; /* Stack text and image vertically */
        height: 100%; /* Full height of App-header */
        overflow: hidden;
        padding-top: 5vh;
    }

    .hero-image{
        margin-top: 5vh;
    }

    .hero-text {
        width: 100%; /* Full width on small screens */
        position: relative; /* Adjust positioning for small screens */
        background: linear-gradient(to top , white 80%, transparent); 
        padding: 10px;
        z-index: 1;
        margin-top: 30vh; /* Space between text and image */
        overflow: hidden; /* Allow scroll if content exceeds container */
    }

    .hero-upper-text {
        padding-bottom: 10px; /* Adjust padding for small screens */
    }

    .hero-text h1 {
        font-size: 8vh; /* Large heading for small screens */
        margin-top: 5vh;
    }

    .hero-text h2 {
        font-size: 4vh; /* Larger subheading for small screens */
        margin-top: 10vh; /* Adjust margin for small screens */
    }

    .hero-text p {
        font-size: 2vh; /* Smaller text size for small screens */
        width: 100%; /* Ensure paragraph spans the width of the text container */
    }
}

@media (max-width: 480px) {
    .App-header {
        flex-direction: column;
        align-items: center;
        height: 80%; /* Full height for mobile screens */
        margin: 20px; 
        padding: 0; /* Remove padding for mobile screens */
    }

    .mobile-view {
        display: block; /* Show on mobile */
    }

    .laptop-view {
        display: none; /* Hide on mobile */
    }

    .hero-section {
        flex-direction: column; /* Stack text and image vertically */
        height: auto; /* Full height of App-header */
        overflow: hidden;
        margin-top: -50px
    }

    .hero-text {
        width: 100%; /* Full width on small screens */
        position: relative; /* Adjust positioning for small screens */
        background: linear-gradient(to top , white 80%, transparent); 
        padding: 10px;
        z-index: 1;
        margin-top: 25vh; /* Space between text and image */
        overflow: hidden; /* Allow scroll if content exceeds container */
    }

    .hero-upper-text {
        padding-bottom: 10px; /* Adjust padding for small screens */
    }
    .hero-text h1 {
        font-size: 6vh; /* Slightly smaller for very small screens */
    }

    .hero-text h2 {
        font-size: 3.5vh; /* Adjust subheading size for very small screens */
    }

    .hero-text p {
        font-size: 1.8vh; /* Further reduce paragraph text size */
    }
}
