/* SneakerAppPage.css */
.sneaker-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
}

.sneaker-photo-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 70%;
  gap: 20px;
}

.sneaker-card1, .sneaker-card2 {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 45vh;
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
  padding: 20px;
}

.sneaker-card1 {
  background-image: url('../../public/sneaker1.png');
  width: 60%;
}

.sneaker-card2 {
  background-image: url('../../public/sneaker2.png');
  width: 40vh;
}

.sneaker-card1 h2, .sneaker-card2 h2 {
  text-align: left;
  color: black;
}

.sneaker-heading {
  font-size: 24px;
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
}

.sneaker-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.description-section p {
  padding-right: 20px;
  text-align: right;
  max-width: 40vh;
  font-size: 20px;
  border-radius: 20px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .sneaker-photo-section {
      width: 100%;
      justify-content: space-around;
  }

  .sneaker-card1, .sneaker-card2 {
      width: 45%;
      height: 40vh;
  }

  .description-section p {
      font-size: 18px;
      max-width: 60vh;
  }
}

@media (max-width: 768px) {
  .sneaker-section {
      flex-direction: column;
      align-items: flex-start;
  }

  .sneaker-photo-section {
      width: 100%;
      flex-direction: column;
      align-items: center;
  }

  .sneaker-card1, .sneaker-card2 {
      width: 80%;
      height: 35vh;
  }

  .sneaker-card1 h2, .sneaker-card2 h2 {
    display: none;
  }

  .description-section p {
      text-align: center;
      max-width: 80%;
      font-size: 18px;
  }
}

@media (max-width: 480px) {
  .sneaker-card1, .sneaker-card2 {
      width: 90%;
      height: 30vh;
  }

  .sneaker-card1 h2, .sneaker-card2 h2 {
    display: none;
  }

  .description-section p {
      text-align: center;
      max-width: 100%;
      font-size: 16px;
      padding: 10px;
  }
}
