/* Container for the section */
.services-main {
    width: 100%; /* Full width */
    height: 120vh; /* Full viewport height */
    overflow: hidden; /* Hide any overflow content */
    position: relative; /* Ensure positioning context for child elements */
}

/* Header styling */
.services-main h1 {
    margin: 0;
    font-size: 10vh; /* Responsive font size */
    color: black;
    font-family: "Bricolage Grotesque", sans-serif;
    text-align: center;
    margin-top: 10vh;
    z-index: 2;
}

/* Grid container styling */
.grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Distribute space evenly */
    padding: 20px;
    z-index: 1;
    gap: 20px; /* Space between cards */
    background-color: #f5f5f5;
    max-width: 100%; /* Ensure it doesn't exceed container width */
    box-sizing: border-box; /* Include padding in width calculations */
}

/* Basic styling for grid layout */
.grid-container > div {
    flex: 1 1 20%; /* Adjust to have 4 cards per row */
    min-width: 200px; /* Minimum width of cards */
    max-width: 400px; /* Maximum width of cards */
    position: relative; /* Ensure cards are positioned relative to their container */
    padding-left: 10px;
    overflow: hidden; /* Hide any overflowing content */
    box-sizing: border-box; /* Include padding and border in the width/height calculations */
    transition: transform 1s ease-out; /* Smooth transition for the transform property */
}

/* Demo card styling */
.lower-demo-card {
    border-radius: 10px;
    padding: 20px;
    background: linear-gradient(to bottom, white, transparent);
    transition: transform 0.2s;
    height: 20vh; /* Adjust to fit within the parent */
    width: 40vh; /* Adjust to fit within the parent */
    margin-top: 20px;
    box-sizing: border-box;
    z-index: 1;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
    .services-main{
        height: auto;
    }
    
    .grid-container {
        width: 100%;
        flex-direction: row; /* Stack cards vertically */
        flex-wrap: wrap;
        gap: 10px;
        /* background-color: red; */
    }
    
    .grid-container > div {
        max-width: 100%; /* Adjust width to fit in viewport */
        padding: 0;
        background-color: blanchedalmond;
    }
    
    .lower-demo-card {
        display: none;
    }
}
