.faq-container {
    width: 80%;
    margin: 0 auto;
    position: relative;
    margin-bottom: 30px;
}

.faq-container h1 {
    margin: 0;
    font-size: 10vh;
    color: black;
    font-family: "Bricolage Grotesque", sans-serif;
    text-align: center;
    padding-bottom: 30px;
}

.faq-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    /* background-color: rgba(235, 235, 235, 0.755); */
    background: linear-gradient(to bottom, white, transparent);
    padding: 20px;
    border-radius: 10px;
    /* Ensure vertical alignment */
    gap: 10px;
}

.faq-item {
    border-bottom: 1px solid #ddd;
    overflow: hidden;
}

.faq-question {
    /* background-color: #f9f9f9; */
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-answer {
    padding: 0px 30px 30px 30px;
    color: gray;
    /* background-color: white; */
    border-radius: 10px;
    max-height: 0;
    /* Hide content by default */
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    /* Animation for opening/closing */
    opacity: 0;
    /* Hide content by default */
}

.faq-answer.open {
    max-height: 1000px;
    /* Large enough to show content, adjust as needed */
    opacity: 1;
    /* Show content when expanded */
}

.faq-answer p {
    margin: 0;
}

.more-button {
    background-color: transparent;
    color: black;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin: 20px 0;
}

.more-button:hover{
    color: gray;
}

