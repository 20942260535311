/* SneakerAppPage.css */
.UiProject-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 20px;
}

.UiProject-photo-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 70%;
    gap: 20px;
}

.UiProject-card1,
.UiProject-card2,
.UiProject-card3,
.UiProject-card4,
.UiProject-card5 {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 45vh;
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
    padding: 20px;
}

.UiProject-card4 {
    display: none;
}

.UiProject-card5 {
    display: none;
}

.UiProject-card1 {
    background-image: url('../../public/UiProject1.png');
    background-position-y: 3px;
    width: 70%;
}

.UiProject-card2 {
    background-image: url('../../public/UiProject2.png');
    width: 15%;
}

.UiProject-card3 {
    background-image: url('../../public/UiProject3.png');
    width: 10%;
}

.UiProject-card4 {
    background-image: url('../../public/UiProject4.png');
    width: 10%;
}

.UiProject-card5 {
    background-image: url('../../public/UiProject5.png');
    width: 10%;
}

.UiProject-card1 h2,
.UiProject-card2 h2 {
    text-align: left;
    color: black;
}

.UiProject-heading {
    font-size: 24px;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
}

.UiProject-image {
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.description-section p {
    padding-right: 20px;
    text-align: right;
    max-width: 40vh;
    font-size: 20px;
    border-radius: 20px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .UiProject-photo-section {
        width: 100%;
        justify-content: space-around;
    }

    .UiProject-card1 {
        width: 45%;
        height: 40vh;
    }

    .UiProject-card2,.UiProject-card3{
        display: none;
    }

    .description-section p {
        font-size: 18px;
        max-width: 60vh;
    }
}

@media (max-width: 768px) {
    .UiProject-section {
        flex-direction: column;
        align-items: flex-start;
        border-top: solid 2px grey;
        margin-top: 30px;
    }

    .UiProject-photo-section {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }

    .UiProject-card1 {
        width: 80%;
        height: 35vh;
    }

    .UiProject-card2,
    .UiProject-card3 {
        display: none;
    }

    .UiProject-card4 {
        display: block;
    }

    .UiProject-card5 {
        display: block;
    }

    .description-section p {
        text-align: center;
        max-width: 80%;
        font-size: 18px;
    }
}

@media (max-width: 480px) {

    .UiProject-photo-section-inner {
        display: flex;
        flex-direction: row;
    }

    .UiProject-card1 {
        width: 90%;
        height: 45vh;
        background-position-y: 0px;
    }

    .UiProject-card2,
    .UiProject-card3 {
        display: none;
    }

    .UiProject-card4{
        display: block;
        width: 90%;
        height: 20vh;
    }

    .UiProject-card5{
        display: block;
        width: 90%;
        height: 15vh;
        background-position-x: -12px;
    }

    .UiProject-card1 h2,
    .UiProject-card2 h2 {
        display: none;
    }

    .description-section p {
        text-align: center;
        max-width: 100%;
        font-size: 16px;
        padding: 10px;
    }
}