.navbar {
    background-color: white;
    border-radius: 0 0 20px 20px;
    margin: 0 20px;
    transition: width 0.5s ease, margin 0.5s ease; /* Smooth transitions for width and margin */
    z-index: 1000; /* Ensure navbar stays on top of other content */
}

.navbar.scrolled {
    width: 100%; /* Adjust based on your margin/padding needs */
    margin: 0; /* Remove side margins */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    transition: width 0.5s ease, margin 0.5s ease; /* Ensure transitions are smooth */
}

.contactUs-btn {
    background-color: lightgray; /* Default background color */
    border: none;
    border-radius: 20px;
    padding: 8px 30px;
    color: black;
    transition: background 0.3s ease; /* Smooth transition effect */
}

.contactUs-btn:hover {
    background: #6C5CE7; /* Gradient background on hover */
    color: white; /* Optional: Change text color on hover */
}
