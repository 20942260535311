/* Main container for header section */
.contact-App {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-App-header {
    position: relative;
    width: 97%;
    height: 75vh; 
    overflow: hidden;
    font-family: "Bricolage Grotesque", sans-serif;
    border-radius: 20px;
}

.contact-App h2 {
    font-size: 10vh;
    text-align: center;
    font-family: "Bricolage Grotesque", sans-serif;
    margin-top: 20px;
}

.form-contactUs-btn{
    background-color: lightgray; /* Default background color */
    border: none;
    margin: 5px 0 -10px 0;
    border-radius: 20px;
    padding: 8px 30px;
    color: black;
    transition: background 0.3s ease;
}


.form-contactUs-btn:hover {
    background: #6C5CE7; /* Gradient background on hover */
    color: white; /* Optional: Change text color on hover */
}


/* Video background styling */
.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
}

/* Flexbox container for the hero section */
.contact-hero-section {
    position: relative;
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center; /* Horizontally center the form */
    align-items: center; /* Vertically center the form */
    z-index: 2;
}

/* Contact form styles */
.contact-form {
    position: relative;
    z-index: 3; 
    background: rgb(255, 255, 255); 
    padding: 20px;
    width: 100%;
    max-width: 500px;
    overflow: hidden;
    border-radius: 10px;
}

/* Form group spacing */
.form-group {
    margin-bottom: 15px;
}

/* Form control styling */
.form-control {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
}

/* Submit button styling */
.form-contactUs-btn {
    background-color: lightgray;
    border: none;
    margin-bottom: 10px;
    border-radius: 20px;
    padding: 8px 30px;
    color: black;
    transition: background 0.3s ease;
    font-family: "Bricolage Grotesque", sans-serif;
}

.form-contactUs-btn:hover {
    background: #6C5CE7;
    color: white;
}

/* Message sent alert */
.message-sent-alert {
    color: orange;
}

/* Responsive design for phone and smaller devices */
@media (max-width: 768px) {

    .contact-App{
        margin-bottom: 0;
    }

    .contact-App h2 {
        margin-top: 20px;
    }

    .contact-App-header {
        flex-direction: column;
        height: auto;
        margin: 20px;
        padding: 10px;
    }

    .background-video {
        /* display: none; */
        height: auto;
    }

    .contact-form {
        position: relative;
        z-index: 2; /* Ensure the form is on top of the video */
        background: rgba(255, 255, 255, 0.95); /* Slightly transparent background */
        width: 100%;
        height: auto; /* Let the form take its natural height */
        max-width: none; /* Remove the max-width restriction */
        margin-top: 25vh; /* Add some space between the video and the form */
        padding: 20px;
        border-radius: 10px;
        overflow-y: scroll;
    }

    .contact-hero-section{
        width: 100%;
    }

    .hero-section {
        flex-direction: column;
        height: auto;
    }
}
