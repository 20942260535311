/* SneakerAppPage.css */
.tour-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
}

.tour-photo-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 70%;
  gap: 20px;
}

.tour-card1,
.tour-card2,
.tour-card3 {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 45vh;
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
  padding: 20px;
}

.tour-card1 {
  background-image: url('../../public/tour1.png');
  background-position: 0px 0px;
  width: 30%;
}

.tour-card2 {
  background-image: url('../../public/tour2.png');
  width: 30%;
}

.tour-card3 {
  background-image: url('../../public/tour3.png');
  width: 30%;
}

.tour-card1 h2,
.tour-card2 h2,
.tour-card3 h2 {
  text-align: left;
  color: black;
}

.tour-heading {
  font-size: 24px;
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
}

.tour-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.tour-description-section p {
  padding-left: 20px;
  text-align: left;
  max-width: 40vh;
  font-size: 20px;
  border-radius: 20px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .tour-photo-section {
    width: 100%;
    justify-content: space-around;
  }

  .tour-card1,
  .tour-card2,
  .tour-card3 {
    width: 45%;
    height: 40vh;
  }

  .tour-description-section p {
    font-size: 18px;
    max-width: 60vh;
  }
}

@media (max-width: 768px) {
  .tour-section {
    flex-direction: column;
    align-items: flex-start;
    border-top: solid 2px grey;
  }

  .tour-photo-section {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .tour-card1,
  .tour-card2,
  .tour-card3 {
    width: 80%;
    height: 35vh;
  }

  .tour-card1 h2,
  .tour-card2 h2,
  .tour-card3 h2 {
    display: none;
  }

  .tour-description-section p {
    text-align: center;
    max-width: 80%;
    font-size: 18px;
  }
}

@media (max-width: 480px) {

  .tour-card1,
  .tour-card2,
  .tour-card3 {
    width: 90%;
    height: 30vh;
  }

  .tour-card1 h2,
  .tour-card2 h2,
  .tour-card3 h2 {
    display: none;
  }

  .tour-description-section p {
    text-align: center;
    max-width: 100%;
    font-size: 16px;
    padding: 10px;
  }
}